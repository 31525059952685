import { useState } from "react";
import {
  TextAreaInput,
  TextInput,
} from "src/components/shared/input/FormInput/FormInputSimple";
import Button from "src/components/UI/Button/Button";
import Card from "src/components/UI/Card/Card";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import airportStyles from "src/components/features/search/SearchForm/elements/LocationItemGroup/LocationItemGroup.module.css";
import { FilteredLocationItem } from "src/components/features/search/SearchForm/elements/LocationItemGroup/FilteredLocationItem";
import { useLocaleContext } from "src/context/locale-context";

const ADDITIONAL_SERVICES_OPTIONS = [
  "registeredBaggage",
  "specialBaggage",
  "airportAssistance",
  "hotelReservation",
  "insurance",
  "paymentInstallments",
  "refundableTicket",
];

const MAX_MESSAGE_LENGTH = 700;

function AirportSearchDropdown(onSelect) {
  const [items, setItems] = useState([]);

  return (
    <div style={{ position: "absolute", top: 0, left: 0 }}>
      <Card shadowLg cornerSm>
        <ul
          className={`${airportStyles.cityList} ${airportStyles.container} ${airportStyles.filtered}`}>
          {items.map((item, i) => (
            <FilteredLocationItem
              key={item.cityIata}
              order={i}
              item={item}
              onSelectLocation={onSelect}
            />
          ))}
        </ul>
      </Card>
    </div>
  );
}

function AirportSearchScreenMobile() {}

export function GroupBookingTravelDetails() {
  const { getStringRes } = useLocaleContext();
  const [travelDirection, setTravelDirection] = useState("oneway");
  const [additionalServices, setAdditionalServices] = useState([]);
  const [messageLength, setMessageLength] = useState(0);
  const [departureDate, setDepartureDate] = useState(null);
  const [searchDropdown, setSearchDropdown] = useState({
    departure: null,
    arrival: null,
  });

  const openAirportSearchDropdown = (isDeparture) => {
    setSearchDropdown((current) => {
      return {
        ...current,
        departure: isDeparture ? "dd" : null,
        arrival: isDeparture ? null : "aa",
      };
    });
  };

  const closeAirportSearchDropdown = (isDeparture) => {
    setSearchDropdown((current) => {
      return {
        ...current,
        [isDeparture ? "departure" : "arrival"]: null,
      };
    });
  };

  return (
    <section className="group-booking__travel">
      <Card className="form-container" cornerSm shadowSm>
        <h3>{getStringRes("group.booking.travelInformation")}</h3>
        <div className="divider"></div>
        <div className="travel-info">
          <div className="col">
            <fieldset id="travel-direction-type-select" className="travel-direction">
              {["oneway", "roundtrip"].map((direction, index) => (
                <RadioButton
                  className="travel-direction"
                  key={index}
                  id={direction}
                  name="travelDirection"
                  onChange={() => setTravelDirection(direction)}
                  checked={travelDirection === direction}>
                  <span>{getStringRes(`group.booking.travelType.${direction}`)}</span>
                </RadioButton>
              ))}
            </fieldset>
            <div className="input-group">
              {getStringRes("group.booking.departure.city")}
              <TextInput
                containerClass="input-container"
                label={getStringRes("group.booking.departure.city")}
                name="departureCity"
                id="travel-source"
                type="text"
                // onFocus={() => openAirportSearchDropdown(true)}
                // onBlur={() => closeAirportSearchDropdown(true)}
                required
              />
              {/* {searchDropdown.departure && <AirportSearchDropdown />} */}
            </div>

            <div className="input-group">
              {getStringRes("group.booking.destination.city")}
              <TextInput
                containerClass="input-container"
                id="travel-destination"
                name="destinationCity"
                type="text"
                // onFocus={() => {}}
                // onBlur={() => {}}
                required
              />
            </div>

            <div className="travel-dates">
              <div className="input-group">
                {getStringRes("group.booking.departure.date")}
                <TextInput
                  containerClass="input-container"
                  id="travel-departure"
                  type="date"
                  name="departureDate"
                  onChange={(e) => setDepartureDate(e.target.value)}
                  min={new Date().toISOString().split("T")[0]}
                  required
                />
              </div>
              <div className="input-group">
                {getStringRes("group.booking.return.date")}
                <TextInput
                  containerClass="input-container"
                  id="travel-return"
                  name="returnDate"
                  type="date"
                  min={departureDate || new Date().toISOString().split("T")[0]}
                  disabled={travelDirection === "oneway"}
                  required={travelDirection === "roundtrip"}
                />
              </div>
            </div>

            <div className="travel-passengers">
              <div className="input-group">
                {getStringRes("group.booking.adults")}
                <TextInput
                  containerClass="input-container"
                  id="passengers-adults"
                  name="adults"
                  type="number"
                  min="1"
                  required
                />
              </div>
              <div className="input-group">
                {getStringRes("group.booking.children")}
                <TextInput
                  containerClass="input-container"
                  id="passengers-children"
                  name="children"
                  type="number"
                />
              </div>
              <div className="input-group">
                {getStringRes("group.booking.infants")}
                <TextInput
                  containerClass="input-container"
                  id="passengers-infants"
                  name="infants"
                  type="number"
                />
              </div>
            </div>

            <div className="input-group">
              {getStringRes("group.booking.desired.price")}
              <TextInput
                containerClass="input-container"
                id="travel-price"
                name="price"
                type="number"
                defaultValue=""
                step="0.01"
                min="0"
                required
              />
            </div>
          </div>
          <div className="col">
            <h4>{getStringRes("group.booking.additionalServicesPrompt")}</h4>
            <ul className="additional-services">
              {ADDITIONAL_SERVICES_OPTIONS.map((category, index) => (
                <li
                  className={additionalServices.includes(category) ? "active" : ""}
                  key={index}>
                  <Checkbox
                    id={`additional-service-${category}`}
                    value={getStringRes(`group.booking.additional.opt.${category}`)}
                    isChecked={additionalServices.includes(category)}
                    label={getStringRes(`group.booking.additional.opt.${category}`)}
                    onChange={() =>
                      setAdditionalServices((services) => {
                        if (services.includes(category)) {
                          return services.filter((service) => service !== category);
                        } else {
                          return [...services, category];
                        }
                      })
                    }
                    name="additionalServices"
                    className="additional-service-item"
                    isSameAsMobile
                    checkMarkColor="#fff"
                  />
                </li>
              ))}
            </ul>
          </div>
          <div style={{ gridColumn: "span 2" }} className="divider"></div>
          <div className="bottom">
            <div className="input-group">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {getStringRes("group.booking.message")}
                <span className="counter">
                  {messageLength} / {MAX_MESSAGE_LENGTH}
                </span>
              </div>
              <TextAreaInput
                containerClass="input-container"
                name="message"
                id="message"
                type="text"
                maxLength={MAX_MESSAGE_LENGTH}
                onInput={(e) => setMessageLength(e.target.value.length)}
              />
            </div>
            <Button
              type="submit"
              name={getStringRes("group.booking.submit.request.button")}
              wide
            />
          </div>
        </div>
      </Card>
    </section>
  );
}
