import { BOOKING } from "src/constants/booking";
import { SERVICE_TYPE, CATALOGUE_IDS } from "src/constants/services";
import { selectCatalogueItem } from "src/store/catalogue";
import { customLog } from "../utils";

function additionalServicesListenerEffect(action, listenerApi, serviceKey, cb) {
  customLog(action);
  const _old = listenerApi.getOriginalState().order[serviceKey];
  const _new = listenerApi.getState().order[serviceKey];
  const noService = [SERVICE_TYPE.NOT_SELECTED, SERVICE_TYPE.REFUSED];
  const refusableServices = [
    CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE,
    CATALOGUE_IDS.MBG_SERVICE,
  ];

  if (_old.serviceId !== _new.serviceId) {
    const bookingAction = {
      command: BOOKING.ADD_SERVICE_ADDITIONAL,
      payload: "",
    };

    const catalogue = listenerApi.getState().catalogue.catalogue;
    if (
      refusableServices.includes(serviceKey) &&
      _new.serviceId === SERVICE_TYPE.REFUSED &&
      _old.serviceId === SERVICE_TYPE.NOT_SELECTED
    ) {
      const serviceDetails = selectCatalogueItem(catalogue, serviceKey);
      bookingAction.command = BOOKING.REMOVE_SERVICE_ADDITIONAL;
      bookingAction.payload = {
        id: serviceDetails.data.id,
        name: serviceDetails.data.name,
        backend_id: _old._id,
        explicit_refuse: true,
      };
    } else if (noService.includes(_new.serviceId)) {
      const serviceDetails = selectCatalogueItem(catalogue, _old.serviceId);
      bookingAction.command = BOOKING.REMOVE_SERVICE_ADDITIONAL;
      bookingAction.payload = { id: serviceDetails.data.id, backend_id: _old._id };
    } else {
      const serviceDetails = selectCatalogueItem(catalogue, _new.serviceId);
      bookingAction.payload = { ...serviceDetails.data, backend_id: _new._id };
    }

    cb(bookingAction);
  }
}

export function getServicesListenerOpts(cb) {
  const typesDetails = [
    ["order/changeBookingSMSService", "bookingSMSService"],
    ["order/changeFlightsSMSService", "flightsSMSService"],
    ["order/changeOnlineCheckinService", "onlineCheckin"],
    ["order/changeAirHelpService", "airHelpService"],
    ["order/changeConnectionProtection", "connectionProtection"],
    ["order/changeChangeProtection", "changeProtection"],
  ];
  const servicesListenerOpts = typesDetails.map((details) => {
    return {
      type: details[0],
      effect: (action, listenerApi) =>
        additionalServicesListenerEffect(action, listenerApi, details[1], cb),
    };
  });
  return servicesListenerOpts;
}
