import { createAction } from "@reduxjs/toolkit";

export const addPassenger = createAction("passenger/add", (ageGroup, passengerId) => {
  return {
    payload: { ageGroup, passengerId },
  };
});

export const removePassenger = createAction("passenger/remove", (passengerId, ageGroup) => {
  return {
    payload: { passengerId, ageGroup },
  };
});

export const updatePassenger = createAction(
  "passenger/update",
  (passengerId, ageGroup, details) => {
    return {
      payload: { passengerId, ageGroup, details },
    };
  }
);

export const updateCheckedBaggageInclusion = createAction(
  "passenger/updateCheckedBaggageInclusion",
  (passengerId, doInclude) => {
    return {
      payload: { passengerId, doInclude },
    };
  }
);

export const updateTravelPackage = createAction(
  "flight/updateTravelPackage",
  (travelPackage) => {
    return {
      payload: { travelPackage },
    };
  }
);

export const resetAfterBooking = createAction(
  "order/resetAfterBooking",
  (keepTravelPackage) => {
    return { payload: { keepTravelPackage } };
  }
);

export const addUpdate = createAction("bookingUpdates/addUpdate", (update) => {
  return { payload: update };
});
