export const PROJECT_NAME = window.SERVER_DATA.REACT_APP_PROJ_NAME;
export const MAX_NAME_LEN = 5;
export const MAX_SCREEN_WIDTH_MOBILE = 991;
export const MAX_DEVICE_WIDTH_MOBILE = 991;
export const MODAL_ANIMATION_TIME = 200;

export const LOCALE_TYPE = {
  GB: "en_GB",
  LV: "lv_LV",
  LT: "lt_LT",
  RU: "ru_RU",
};

export const TRIP_TYPE = {
  roundtrip: "roundtrip",
  oneway: "oneway",
  multicity: "multicity",
};

export const PASSENGER = {
  adult: "adult",
  child: "child",
  infant: "infant",
};

export const FARE_PAX_MAP = {
  adt: PASSENGER.adult,
  chd: PASSENGER.child,
  inf: PASSENGER.infant,
};

export const CABIN_CLASS = {
  economy: "economy",
  premiumEconomy: "premium-economy",
  business: "business",
  first: "first",
};

export const CABIN_CLASS_LETTER_MAP = {
  E: CABIN_CLASS.economy,
  W: CABIN_CLASS.premiumEconomy,
  B: CABIN_CLASS.business,
  F: CABIN_CLASS.first,
  A: "all",
};

export const CABIN_CLASS_MAP = {
  economy: "sf.cabin.type.economy",
  "premium-economy": "sf.cabin.type.premiumEconomy",
  business: "sf.cabin.type.business",
  first: "sf.cabin.type.first",
};

export const PASSENGER_TYPE_MAP = {
  adult: { value: "sf.passenger.type.adults", description: "sf.passenger.age.adults" },
  child: {
    value: "sf.passenger.type.children",
    description: "sf.passenger.age.children",
  },
  infant: { value: "sf.passenger.type.infants", description: "sf.passenger.age.infants" },
};

export const SORT = {
  best: "best",
  cheapest: "cheapest",
  fastest: "fastest",
  earlyDeparture: "departure",
  earlyArrival: "arrival",
};

export const FILTER_TYPE = {
  cabinBaggage: "cabinBaggage",
  checkedBaggage: "checkedBaggage",
  stops: "stops",
  nightFlight: "nightFlight",
  nightStop: "nightStop",
  duration: "duration",
  stopover: "stopover",
  departureStartTime: "departureStartTime",
  departureEndTime: "departureEndTime",
  returnStartTime: "returnStartTime",
  returnEndTime: "returnEndTime",
  airline: "airline",
  airport: "airport",
  price: "price",
};

export const STOPS = {
  any: "any",
  direct: "direct",
  one: "one",
  two: "two",
};

export const NIGTH_FLIGHT_FILTER = {
  nightFlight: "nightFlight",
  nightStop: "nightStop",
};

export const BAGGAGE_TYPE_FILTER = {
  cabin: "cabinBaggage",
  checked: "checkedBaggage",
};

export const SEAT_TYPE = {
  random: "random",
  window: "window",
  aisle: "aisle",
  extra_legroom: "extraLegroom",
  sitting_together: "together",
};

export const LOGIN_VENDOR = {
  email: "loginByEMail",
  facebook: "loginByFacebook",
  google: "loginByGoogle",
  apple: "loginByApple",
};

export const SHARE_VENDOR = {
  directLink: "shareByDirectLink",
  whatsapp: "shareByWhatsapp",
  messenger: "shareByMessenger",
  iosMessages: "shareByIOSMessages",
  telegram: "shareByTelegram",
  viber: "shareByViber",
  email: "shareByEmail",
};

export const BOOKING_STEP = Object.freeze({
  searchResults: Object.freeze({
    id: "searchResults",
    parentPath: "search",
    path: "results",
  }),
  fareTypeSelection: Object.freeze({
    id: "fareTypeSelection",
    parentPath: "booking",
    path: "fares",
  }),
  passengerDetailsForm: Object.freeze({
    id: "passengerDetailsForm",
    parentPath: "booking",
    path: "passengers",
  }),
  guaranteeSelection: Object.freeze({
    id: "guaranteeSelection",
    parentPath: "booking",
    path: "guarantee",
  }),
  insuranceSelection: Object.freeze({
    id: "insuranceSelection",
    parentPath: "booking",
    path: "insurance",
  }),
  extraServicesSelection: Object.freeze({
    id: "extraServicesSelection",
    parentPath: "booking",
    path: "services",
  }),
  seatSelection: Object.freeze({
    id: "seatSelection",
    parentPath: "booking",
    path: "seats",
  }),
  supportSelection: Object.freeze({
    id: "supportSelection",
    parentPath: "booking",
    path: "support",
  }),
  checkoutPage: Object.freeze({
    id: "checkoutPage",
    parentPath: "booking",
    path: "checkout",
  }),
});

export const BAG_WEIGHT_CATEGORY = {
  default: "default",
  larger: "larger",
};

export const BILLING_TYPE = {
  company: "companyVATType",
  person: "personVATType",
};

export const DASHBOARD_LINKS = [
  { name: "menu.user.trips", to: "/dashboard/trips" },
  // { name: "menu.user.passengers", to: "/dashboard/passengers" },
  { name: "menu.user.invoices", to: "/dashboard/invoices" },
  // { name: "menu.user.priceAlerts", to: "/dashboard/alerts" },
  // { name: "menu.user.referral", to: "/dashboard/referral" },
  // { name: "menu.user.preferences", to: "/dashboard/preferences" },
  // { name: "menu.user.support", to: "/dashboard/support" },
];

export const SORT_OPTIONS = ["dateDesc", "dateAsc", "priceDesc", "priceAsc"];
