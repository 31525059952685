import { isAnyOf, addListener, nanoid } from "@reduxjs/toolkit";
import { FARE_PAX_MAP, PASSENGER } from "src/constants";
import { CATALOGUE_IDS } from "src/constants/services";
import { addPassenger, removePassenger, updatePassenger } from "src/store/actions";
import { orderActions } from "src/store/order";
import { getPackageFaresList } from "./results-utils";
import { customLog } from "./utils";
import { bookingListeners } from "./listeners";
import { getCountryCode } from "countries-list";
import { buildSearchModelFromTravelPackage, buildSearchQuery } from "./query-utils";

export const rebuildSearchQuery = (travelPackage, passengerData) => {
  let rebuiltSearchQ, searchModel;
  searchModel = buildSearchModelFromTravelPackage(travelPackage, passengerData);
  rebuiltSearchQ = buildSearchQuery(searchModel);
  rebuiltSearchQ = rebuiltSearchQ
    .split("&")
    .filter((p) => !p.startsWith("d_ct") || !p.startsWith("s_ct"))
    .join("&");
  return rebuiltSearchQ;
};

export function getPassengersFromFaresList(travelPackage) {
  const passengerData = [];
  let paxFares = getPackageFaresList(travelPackage);
  let paxNum = paxFares.length;

  for (let i = 0; i < paxNum; i++) {
    const newPax = { ageGroup: FARE_PAX_MAP[paxFares[i].type], id: nanoid() };
    passengerData.push(newPax);
  }

  return passengerData;
}

export function listenToPassengersUpdates(dispatch, callback) {
  const listenerConfig = {
    matcher: isAnyOf(addPassenger, removePassenger, updatePassenger),
    effect: (action, listenerApi) => {
      customLog(action);
      const _previousPax = listenerApi.getOriginalState().booking.passengers;
      const _newPax = listenerApi.getState().booking.passengers;
      const travelPackage = listenerApi.getState().booking.travelPackage;

      const groupedPrevPaxs = _previousPax.reduce((acc, pax) => {
        acc[pax.ageGroup] = (acc[pax.ageGroup] || []).concat(pax);
        return acc;
      }, {});
      const groupedNewPaxs = _newPax.reduce((acc, pax) => {
        acc[pax.ageGroup] = (acc[pax.ageGroup] || []).concat(pax);
        return acc;
      }, {});

      const prevSittingPax =
        groupedPrevPaxs[PASSENGER.adult].length + groupedPrevPaxs[PASSENGER.child]?.length;
      const newSittingPax =
        groupedNewPaxs[PASSENGER.adult].length + groupedNewPaxs[PASSENGER.child]?.length;

      if (
        (prevSittingPax > 1 && newSittingPax === 1) ||
        (prevSittingPax === 1 && newSittingPax > 1)
      ) {
        travelPackage.segments.forEach((flight, segment) => {
          dispatch(
            orderActions.changeSeatType({
              segment: segment,
              serviceId: CATALOGUE_IDS.seats.random,
              airline: flight.carrier.code,
            })
          );
        });
      }

      const fares = getPackageFaresList(travelPackage);
      const groupedFares = fares.reduce((acc, fare) => {
        acc[fare.type] = (acc[fare.type] || []).concat(fare);
        return acc;
      }, {});

      if (
        (groupedFares["adt"] || []).length !== groupedNewPaxs[PASSENGER.adult].length ||
        (groupedFares["chd"] || []).length !==
          (groupedNewPaxs[PASSENGER.child] || [])?.length ||
        (groupedFares["inf"] || []).length !== (groupedNewPaxs[PASSENGER.infant] || [])?.length
      ) {
        const updatedSearchQuery = rebuildSearchQuery(travelPackage, _newPax);
        customLog(updatedSearchQuery);
        callback(
          updatedSearchQuery,
          travelPackage.mergeKey,
          travelPackage.data_provider,
          _newPax,
          _previousPax
        );
      }
    },
  };
  return dispatch(addListener(listenerConfig));
}

export function setUpBookingListeners(dispatch, cb) {
  const listenerOpts = [
    bookingListeners.getContactDataListenerOpts,
    bookingListeners.getPassengersListenerOpts,
    bookingListeners.getBillingInfoListenerOpts,
    bookingListeners.getServicesListenerOpts,
    bookingListeners.getBaggageProtectionListenerOpts,
    bookingListeners.getInsuranceServiceListenerOpts,
    bookingListeners.getSupportServiceListenerOpts,
    bookingListeners.getSeatingListenerOpts,
    bookingListeners.getBaggageListenerOpts,
    bookingListeners.getBaggageInclusionListenerOpts,
    bookingListeners.getTermsAcceptanceListenerOpts,
    bookingListeners.getPaymentTypeListenerOpts,
    bookingListeners.getOffersOptInListenerOpts,
    bookingListeners.getNewsletterSubListenerOpts,
    bookingListeners.getCatalogueListenerOpts,
    bookingListeners.getCartChangeListenerOpts,
  ];

  const unsubscribeList = listenerOpts.flatMap((getOptsFunc) => {
    const funcOpts = getOptsFunc(cb);
    if (Array.isArray(funcOpts)) {
      return funcOpts.map((getOpts) => dispatch(addListener(getOpts)));
    } else {
      return dispatch(addListener(funcOpts));
    }
  });

  return () => unsubscribeList.forEach((unsubsribe) => unsubsribe());
}

export const getOrderPayload = (
  bookingID,
  bookingNumber,
  locale,
  totalAmount,
  paymentData,
  billingData,
  email
) => {
  const targetCountry = window.SERVER_DATA.REACT_APP_DEFAULT_DOMAIN_LOCALE ?? "lv_LV";
  const paymentCountryCode = targetCountry.split("_")[1];

  const orderPayload = {
    accessKey: window.SERVER_DATA.REACT_APP_MONTONIO_ACCESS_KEY,
    merchantReference: bookingID,
    returnUrl: `${window.location.origin}/order/${bookingID}/thankyou?booking-number=${bookingNumber}&lang=${locale}`,
    currency: "EUR",
    grandTotal: totalAmount,
    locale: locale,
    payment: {
      method: paymentData.type,
      methodDisplay: paymentData.description,
      methodOptions: {
        paymentDescription: "Payment for order " + bookingNumber,
        preferredCountry: paymentCountryCode,
        preferredProvider: paymentData.vendor ?? "",
      },
      amount: totalAmount,
      currency: "EUR",
    },
  };

  if (billingData.isVATRequired) {
    const billingDetails = billingData.isCompany ? billingData.company : billingData.person;
    orderPayload.billingAddress = {
      firstName: billingData.isCompany ? billingDetails.companyName : billingDetails.name,
      lastName: billingData.isCompany ? "" : billingDetails.surname,
      email: email,
      addressLine1: billingDetails.address,
      country: getCountryCode(billingDetails.country),
    };
  }

  return orderPayload;
};
