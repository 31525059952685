import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";


export function getTermsAcceptanceListenerOpts(cb) {
  return {
    type: "booking/updateTermsAgreementStatus",
    effect: (action, listenerApi) => {
      customLog(action);
      const agreementState = listenerApi.getState().booking.isTermsAgreed;
      const bookingAction = {
        command: BOOKING.UPDATE_TERMS_ACCEPTANCE,
        payload: { isTermsAgreed: agreementState },
      };
      cb(bookingAction);
    },
  };
}
