import { BOOKING } from "src/constants/booking";
import { SERVICE_TYPE } from "src/constants/services";
import { selectCatalogueItem } from "src/store/catalogue";
import { customLog } from "../utils";

export function getInsuranceServiceListenerOpts(cb) {
  return {
    type: "order/changeTravelInsuranceType",
    effect: (action, listenerApi) => {
      customLog(action);

      const _new = listenerApi.getState().order.travelInsurance;
      const _old = listenerApi.getOriginalState().order.travelInsurance;
      const noService = [SERVICE_TYPE.NOT_SELECTED, SERVICE_TYPE.REFUSED];

      if (_new.serviceId !== _old.serviceId) {
        const bookingActionsQueue = [];
        const catalogue = listenerApi.getState().catalogue.catalogue;
        const insuranceData = listenerApi
          .getState()
          .catalogue.serviceData.find((d) => d.name === "TRAVEL_INSURANCE");

        //if new selection is a concrete service && was concrete/refused/unselected
        if (!noService.includes(_new.serviceId)) {
          //if old selection was concrete
          if (!noService.includes(_old.serviceId)) {
            bookingActionsQueue.push({
              command: BOOKING.REMOVE_SERVICE_ADDITIONAL,
              payload: { id: insuranceData.id, backend_id: _old._id },
            });
          }
          const serviceDetails = selectCatalogueItem(catalogue, _new.serviceId);
          const bookingAction = {
            command: BOOKING.ADD_SERVICE_ADDITIONAL,
            payload: { ...serviceDetails.data, id: insuranceData.id, backend_id: _new._id },
          };
          bookingActionsQueue.push(bookingAction);
          //if service is refused straight away on first load
        } else if (_old.serviceId === SERVICE_TYPE.NOT_SELECTED) {
          bookingActionsQueue.push({
            command: BOOKING.REMOVE_SERVICE_ADDITIONAL,
            payload: {
              id: insuranceData.id,
              name: insuranceData.name,
              explicit_refuse: true,
            },
          });
          //if service is now refused && before was concrete
        } else {
          bookingActionsQueue.push({
            command: BOOKING.REMOVE_SERVICE_ADDITIONAL,
            payload: { id: insuranceData.id, backend_id: _old._id },
          });
        }

        bookingActionsQueue.forEach((nextAction) => cb(nextAction));
      }
    },
  };
}
