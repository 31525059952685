import { IconArrowNarrowRight, IconArrowsRightLeft } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import ButtonWithIcon from "src/components/UI/ButtonWithIcon/ButtonWithIcon";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { TRIP_TYPE as TRIP } from "src/constants";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./TravelTypeItemGroup.module.css";

function TravelTypeButton({ item, className, onSelect }) {
  return (
    <li className={className}>
      <ButtonWithIcon
        imgHeight={12}
        name={item.type}
        imgEl={item.img}
        onClick={() => onSelect(item.type)}>
        {item.title}
      </ButtonWithIcon>
    </li>
  );
}

function TravelTypeRadio({ item, tripType, onSelect }) {
  const location = useLocation();
  const isResultsPage = location.pathname.startsWith("/search/results");
  const onInputChangeHandler = (isChecked) => {
    if (isChecked) {
      onSelect(item.type);
    }
  };

  return (
    <li>
      <RadioButton
        className={isResultsPage ? classes.results : ""}
        id={item.type}
        name={item.type}
        onChange={onInputChangeHandler}
        checked={item.type === tripType}>
        <span className={classes.radioText}>{item.title}</span>
      </RadioButton>
    </li>
  );
}

function TravelTypeItemGroup({ onSelected, tripType }) {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();
  // const classNames = [classes.leftmost, classes.middle, classes.rightmost];
  const classNames = [classes.leftmost, classes.rightmost];

  const tripTypeItems = [
    {
      img: ({ imageClass }) => {
        return <IconArrowsRightLeft size={14} />;
      },
      type: TRIP.roundtrip,
      title: getStringRes("sf.travelType.roundtrip"),
    },
    {
      img: ({ imageClass }) => {
        return <IconArrowNarrowRight size={14} />;
      },
      type: TRIP.oneway,
      title: getStringRes("sf.travelType.oneway"),
    },
    // {
    //   img: multicityArr,
    //   type: TRIP.multicity,
    //   title: getStringRes("sf.travelType.multicity"),
    // },
  ];

  const onSelectTripTypeHandler = (type) => {
    if (type !== tripType) {
      onSelected(type);
    }
  };

  return (
    <ul
      className={`travel-types-group ${classes.container} ${classes.directions} ${
        isMobile ? "" : classes.desktop
      }`}>
      {tripTypeItems.map((item, i) => {
        const itemProps = {
          item: item,
          onSelect: onSelectTripTypeHandler,
        };
        if (isMobile) {
          return (
            <TravelTypeButton
              {...itemProps}
              key={item.type}
              className={`${tripType === item.type ? classes.selected : ""} ${classNames[i]}`}
            />
          );
        } else {
          return <TravelTypeRadio key={item.type} {...itemProps} tripType={tripType} />;
        }
      })}
    </ul>
  );
}

export default TravelTypeItemGroup;
