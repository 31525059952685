import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import { bookingOrderApi } from "src/services/api";
import { bookingActions } from "./booking";
import { addUpdate } from "./actions";
import axios from "axios";

const initialState = {
  updateQueue: [], // updates that are yet to be sent
  pending: false, // send operation progress
  currentBatch: [], // currently sent updates
  maxRetries: 3, // max retry attempts for failed batches
  error: false,
};

async function updateWithRetry(updates, correlationId, maxRetries, signal) {
  let timeout = null;

  const handleAbort = () => {
    source.cancel(); // Cancel the request if the signal is aborted
    console.log("Batch logic: Request was aborted.");
    clearTimeout(timeout);
  };
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", handleAbort);

  try {
    for (let retryCount = 0; retryCount < maxRetries; retryCount++) {
      if (signal.aborted) {
        console.log("Batch logic: Request was aborted before retrying: #", retryCount);
        console.log("Batch logic: source token", source.token);
        throw new Error("Request aborted");
      }

      try {
        console.log(
          "Batch logic: sending batch - making API call. source token",
          source.token
        );
        const response = await bookingOrderApi.batchUpdate(
          updates,
          correlationId,
          source.token
        );

        if (response && response.data) {
          console.log(response.data);
          return response.data;
        }
      } catch (err) {
        console.log(`Batch logic: Update retry ${retryCount + 1}/${maxRetries} failed:`, err);
        if (retryCount === maxRetries - 1) {
          throw err;
        } else {
          timeout = await new Promise((resolve) =>
            setTimeout(resolve, 2000 * (retryCount + 1))
          );
        }
      }
    }
  } finally {
    console.log("Batch logic: Removing event listener for abort.");
    signal.removeEventListener("abort", handleAbort);
  }
}

function processResponseUpdate(dispatch, update) {
  const { payload } = update;
  if (!payload) return;
  if (payload.passenger_id && payload.original_id) {
    dispatch(
      bookingActions.setPassengerServerIdentifier({
        id: payload.original_id,
        passenger_id: payload.passenger_id,
      })
    );
  }
}

async function batchUpdate(dispatch, updatesState, correlationId, rejectWithValue, signal) {
  dispatch(setPending(true));
  try {
    const responseUpdates = await updateWithRetry(
      updatesState.currentBatch,
      correlationId,
      updatesState.maxRetries,
      signal
    );
    console.log("Sent batch:", updatesState.currentBatch);
    responseUpdates.forEach((upd) => processResponseUpdate(dispatch, upd));
  } catch (error) {
    console.error("Failed to send batch:", error);
    dispatch(setPending(false));
    dispatch(setError(true));
    return rejectWithValue("Failed to send batch");
  }
}

// Async thunk to send updates
export const sendNextUpdateBatch = createAsyncThunk(
  "bookingUpdates/sendNextBatch",
  async (correlationId, { signal, getState, dispatch, rejectWithValue, fulfillWithValue }) => {
    const updatesState = getState().bookingUpdates;
    if (updatesState.currentBatch.length === 0) {
      return fulfillWithValue("No updates to send");
    }

    return batchUpdate(dispatch, updatesState, correlationId, rejectWithValue, signal);
  },
  {
    condition: (_, { getState }) => {
      return !(getState().bookingUpdates.pending || getState().bookingUpdates.error);
    },
  }
);

export const commitAllUpdates = createAsyncThunk(
  "bookingUpdates/commitAllUpdates",
  async (correlationId, { getState, dispatch, rejectWithValue, fulfillWithValue }) => {
    // Wait until `sendNextUpdateBatch` is not pending (if it's running)
    const CHECK_INTERVAL = 1500;
    let retriesLeft = 5;
    let start = Date.now();
    while (getState().bookingUpdates.pending && retriesLeft > 0) {
      console.log("Batch logic: Waiting for previous updates to be sent...", retriesLeft);
      await new Promise((resolve) => setTimeout(resolve, CHECK_INTERVAL));
      retriesLeft--;
    }
    console.log("Batch logic: Previous updates sent in", Date.now() - start, "ms");

    // Forcefully send all remaining updates in queue
    const { pending, currentBatch } = getState().bookingUpdates;
    if (pending) {
      dispatch(setError(true));
      dispatch(setPending(false));
      return rejectWithValue("Cannot commit updates while previous batch is pending");
    } else if (currentBatch.length > 0) {
      console.log("Batch logic: Force sending remaining updates...");
      return dispatch(sendNextUpdateBatch(correlationId)).unwrap();
    } else {
      console.log("Batch logic: No updates to sendю");
      return fulfillWithValue("No updates to send");
    }
  }
);

const bookingUpdatesSlice = createSlice({
  name: "bookingUpdates",
  initialState,
  reducers: {
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    clearUpdates: () => {
      return initialState;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUpdate, (state, action) => {
        const update = { ...action.payload, idempotency: nanoid() };
        if (state.pending) {
          state.updateQueue.push(update);
        } else {
          state.currentBatch.push(update);
        }
      })
      .addCase(sendNextUpdateBatch.fulfilled, (state) => {
        state.currentBatch = state.updateQueue.splice(0, state.updateQueue.length);
        state.pending = false;
      });
  },
});

export const { setError, setPending, clearUpdates } = bookingUpdatesSlice.actions;
export default bookingUpdatesSlice.reducer;
