import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MODAL_TYPE } from "src/constants/modal-definitions";
import { useModalContext } from "src/context/modal-stack-context";

export default function useLoginPopup() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { openModal } = useModalContext();

  useEffect(() => {
    if (searchParams.get("login") === "true") {
      const accessedPage = searchParams.get("from") || "/";
      searchParams.delete("login");
      searchParams.delete("from");
      openModal(MODAL_TYPE.loginOptions, {
        onAfterLogin: () => {
          console.log(`${accessedPage}?${searchParams.toString()}`);
          navigate(`${accessedPage}?${searchParams.toString()}`, { replace: true });
        },
        onClose: () => {
          navigate(`/?${searchParams.toString()}`, { replace: true });
        },
      });
    }
  }, [location.pathname, navigate, openModal, searchParams]);
}
