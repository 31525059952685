import { Fragment, useCallback } from "react";
import cityIcon from "src/assets/img/cityIcon.png";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { AirportItem } from "./AirportItem";
import classes from "./LocationItemGroup.module.css";

export function FilteredLocationItem({ order, item, onSelectLocation }) {
  const { currentLocale } = useLocaleContext();
  const showCityAndAirport = item.airport.length > 1;
  const showOnlyCity = item.singleAirportInCity;
  const showOnlyAiport = item.airport.length === 1 && !item.singleAirportInCity;

  const transformDataItem = useCallback(
    (dataItem) => {
      const LANG = currentLocale.split("_")[0].toUpperCase();
      const newDataItem = {};
      const cities = Object.fromEntries(dataItem.city.flatMap((c) => Object.entries(c)));
      const countries = Object.fromEntries(dataItem.country.flatMap((c) => Object.entries(c)));

      newDataItem.cityIata = dataItem.cityIata;
      newDataItem.city = cities["EN"];
      newDataItem.localizedCity = cities[LANG] || newDataItem.city;
      newDataItem.country = countries["EN"];
      newDataItem.localizedCountry = countries[LANG] || newDataItem.country;
      newDataItem.airport = [];
      newDataItem.displayNames = cities;
      item.airport.forEach((a) => {
        const airportData = {};
        airportData.iata = a.iata;
        airportData.airportName = a.airportName;
        airportData.localizedName = a.airportName;
        newDataItem.airport.push(airportData);
      });
      return newDataItem;
    },
    [item, currentLocale]
  );

  const onSelectHandler = (city, iata, isCity, cityIata, displayNames) => {
    onSelectLocation(
      { city: city, iata: iata, cityIata: cityIata, names: displayNames },
      isCity
    );
  };

  const props = {
    onSelectHandler: onSelectHandler,
    item: transformDataItem(item),
    cityOrder: order,
  };

  if (showCityAndAirport) {
    return <FilteredCityWithAirports {...props} />;
  } else if (showOnlyAiport) {
    return <FilteredAirportOnly {...props} airport={props.item.airport[0]} />;
  } else if (showOnlyCity) {
    return <FilteredCityOnly {...props} />;
  }
}

function FilteredAirportOnly({ item, cityOrder, airport, onSelectHandler }) {
  return (
    <div key={item.city} className={`${classes.cityItem} ${classes.airportOnly}`}>
      <AirportItem
        order={{ cityOrder: cityOrder, airportOrder: 0 }}
        key={airport.iata ? airport.iata : item.city + "_all"}
        code={airport.iata}
        name={airport.airportName}
        city={item.localizedCity}
        country={item.localizedCountry}
        onSelect={() =>
          onSelectHandler(item.city, airport.iata, false, item.cityIata, item.displayNames)
        }
      />
    </div>
  );
}

function FilteredCityOnly({ item, onSelectHandler }) {
  return (
    <li key={item.city} className={`${classes.cityItem} ${classes.cityOnly}`}>
      <div
        className={classes.row}
        onClick={() =>
          onSelectHandler(item.city, item.cityIata, true, item.cityIata, item.displayNames)
        }>
        <img height={18} alt="" src={cityIcon} />
        <div className={classes.container}>
          <p>{item.localizedCity + ", " + item.localizedCountry}</p>
          <p>{`${item.airport[0]?.iata} ${item.airport[0]?.airportName}`}</p>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}></div>
      </div>
    </li>
  );
}

function FilteredCityWithAirports({ item, cityOrder, onSelectHandler }) {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();

  const airportsRow = (
    <div className={classes.row}>
      <div className={classes.col}></div>
      <ul className={`${classes.airportList} ${classes.container}`}>
        {item.airport.map((airport, i) => (
          <AirportItem
            order={{ cityOrder: cityOrder, airportOrder: i }}
            key={airport.iata ? airport.iata : item.city + "_all"}
            code={airport.iata}
            name={airport.airportName}
            city={item.localizedCity}
            country={item.localizedCountry}
            onSelect={() =>
              onSelectHandler(item.city, airport.iata, false, item.cityIata, item.displayNames)
            }
          />
        ))}
      </ul>
    </div>
  );

  if (isMobile) {
    return (
      <li key={item.city} className={classes.cityItem}>
        <div
          className={classes.row}
          onClick={() =>
            onSelectHandler(item.city, item.cityIata, true, item.cityIata, item.displayNames)
          }>
          <img height={18} alt="" src={cityIcon} />
          <p>
            {`${item.localizedCity}, ${item.localizedCountry} `}
            <span>{getStringRes("sf.airports.all")}</span>
          </p>
        </div>
        {airportsRow}
      </li>
    );
  } else {
    return (
      <Fragment>
        <li key={item.city} className={classes.cityItem}>
          <div
            className={classes.row}
            onClick={() =>
              onSelectHandler(item.city, item.cityIata, true, item.cityIata, item.displayNames)
            }>
            <img height={18} alt="" src={cityIcon} />
            <p>
              {`${item.localizedCity}, ${item.localizedCountry} `}
              <span>{getStringRes("sf.airports.all")}</span>
            </p>
          </div>
        </li>
        {airportsRow}
      </Fragment>
    );
  }
}
