import { useEffect, useState } from "react";
import airportIcon from "src/assets/img/airportIcon.png";
import classes from "./LocationItemGroup.module.css";

export function AirportItem({ order, code, name, city, country, onSelect }) {
  const baseClass = `${classes.airportItem} ${classes.container}`;
  const [classname, setClassname] = useState(baseClass);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setClassname(`${baseClass} ${classes.anim}`);
    }, 50 * order.airportOrder);
    return () => clearTimeout(timeoutId);
  });

  return (
    <li className={classname} onClick={onSelect}>
      <img height={18} alt="" src={airportIcon} />
      <div className={classes.container}>
        <p>{city + ", " + country}</p>
        <p>{code + " " + name}</p>
      </div>
    </li>
  );
}
