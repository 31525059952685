import { isRoundTrip } from "src/utils/results-utils";
import { getSelectedLocale } from "src/utils/storage-utils";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";
import { bookingOrderApi, ticketApi } from "src/services/api";
import { customLog } from "src/utils/utils";
import { defer, json } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import { RestoreUtil } from "./utils/booking-restore-utils";

function formatFlightDate(timestampSeconds, lang) {
  const date = new Date(timestampSeconds * 1000);
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);
  return formattedDate;
}

function formatDataItem(order, lang, i) {
  let trip = order.booking;
  trip.tripType = isRoundTrip(trip) ? "roundtrip" : "oneway";
  trip.from = trip.segments[0].dep.city.title?.split(",")[0];
  const desination = trip.segments_direction[0].at(-1);
  trip.to = trip.segments[desination].arr.city.title?.split(",")[0];
  const startDateStr = formatFlightDate(trip.segments[0].dep.timestamp, lang);
  const endDateStr = formatFlightDate(trip.segments.at(-1).arr.timestamp, lang);
  trip.dates =
    trip.tripType === "roundtrip" ? `${startDateStr} - ${endDateStr}` : startDateStr;
  trip.imageUrl =
    `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
    `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cities/` +
    `${trip.segments[desination].arr.city.code}.jpg`;
  trip.paid = trip.amountPaid >= trip.totalBookingPrice;
  return trip;
}

export async function loader({ request, params, user }) {
  console.log("userTripDetailsLoader");
  if (isLangAbsent(request)) return redirectWithLang(request);
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const lang = searchParams.get("lang") || getSelectedLocale().split("_")[0];
  if (!user) return null;

  const trip = bookingOrderApi
    .getOrderById(params.orderId)
    .then((res) => {
      const order = res.data;
      let trip = formatDataItem(order, lang, 0);
      trip.past = trip.segments[0].dep.timestamp * 1000 < Date.now();
      console.log(trip);

      if (order.updates) {
        const restoredData = RestoreUtil.restoreUserBookingDataWithDocuments(order.updates);
        trip = { ...trip, ...restoredData };
      } else {
        throw Error("No updates found");
      }
      return trip;
    })
    .catch((err) => {
      customLog(err);
      throw json({ data: err });
    });

  const files = ticketApi
    .getDocuments(params.orderId)
    .then((res) => {
      const data = res.data;
      return data.map((f) => ({
        name: f.path?.split("/").pop(),
        url: f.path,
        type: f.category,
        id: nanoid(),
      }));
    })
    .catch((err) => {
      customLog(err);
      throw json({ data: err });
    });

  return defer({ trip, files });
}
