import { createSlice } from "@reduxjs/toolkit";
import { bookingModel, passengerModel } from "./models";
import { customLog } from "src/utils/utils";
import { BILLING_TYPE, PASSENGER } from "src/constants";
import {
  addPassenger,
  removePassenger,
  resetAfterBooking,
  updateCheckedBaggageInclusion,
  updatePassenger,
  updateTravelPackage,
} from "./actions";
import { getServiceFee } from "src/utils/results-utils";

const bookingSlice = createSlice({
  name: "booking",
  initialState: bookingModel,
  reducers: {
    setPassengerState(state, action) {
      state.passengerState = { ...state.passengerState, ...action.payload };
    },
    setUserId(state, action) {
      state.travelPackage.userId = action.payload;
    },
    setPassengerServerIdentifier(state, action) {
      const paxID = action.payload.id;
      const serverId = action.payload.passenger_id;
      const pIdx = state.passengers.findIndex((p) => p.id === paxID);
      if (pIdx !== -1) {
        state.passengers[pIdx].passenger_id = serverId;
      }
    },
    commitPassenger(state, action) {
      const pIdx = state.passengers.findIndex((p) => p.id === action.payload);
      if (pIdx > -1) {
        state.passengers[pIdx].committed = true;
      }
    },
    updateContactData(state, action) {
      const { email, tel, telCode } = action.payload;
      state.contact.email = email;
      state.contact.tel = tel;
      state.contact.telCode = telCode;
    },
    updateCompanyBillingData(state, action) {
      const { address, country, companyID, VATNumber, companyName } = action.payload;
      state.billing.company.address = address;
      state.billing.company.country = country;
      state.billing.company.companyName = companyName;
      state.billing.company.VATNumber = VATNumber;
      state.billing.company.companyID = companyID;
    },
    updatePersonalBillingData(state, action) {
      const { name, surname, address, country } = action.payload;
      state.billing.person.name = name;
      state.billing.person.surname = surname;
      state.billing.person.address = address;
      state.billing.person.country = country;
    },
    changeBillingType(state, action) {
      const isCompany = action.payload === BILLING_TYPE.company;
      state.billing.isCompany = isCompany;
    },
    toggleBillingRequired(state) {
      state.billing.isVATRequired = !state.billing.isVATRequired;
      if (state.billing.isVATRequired) {
        state.billing.isCompany = true;
      }
    },
    changeNewsletterSubscription(state, action) {
      customLog("changing newsletter sub");
      state.contact.isSubscribedNewsletter = action.payload;
    },
    updateFormWarningStatus(state, action) {
      const step = action.payload.step;
      state.step[step].isFormIncomplete = action.payload.isIncomplete;
    },
    incrementValidationAttempts(state, action) {
      const step = action.payload;
      const MAX_ATTEMPTS = 99;
      if (state.step[step].validationAttempts >= MAX_ATTEMPTS) {
        state.step[step].validationAttempts = 1;
      } else {
        state.step[step].validationAttempts++;
      }
    },
    resetValidationAttempts(state, action) {
      const step = action.payload;
      state.step[step].validationAttempts = 0;
    },
    resetFormState(state, action) {
      const step = action.payload;
      state.step[step].validationAttempts = 0;
      state.step[step].isFormIncomplete = false;
    },
    updatePaymentType(state, action) {
      state.payment.type = action.payload;
      state.payment.vendor = "";
    },
    updatePaymentVendor(state, action) {
      state.payment.vendor = action.payload;
    },
    updateTermsAgreementStatus(state, action) {
      state.isTermsAgreed = action.payload;
    },
    updateSkipOffersEmailsStatus(state, action) {
      state.isOffersOptedOut = action.payload;
    },
    resetPassengers(state) {
      state.passengers = bookingModel.passengers;
    },
    resetBookingState(state) {
      return { ...bookingModel, travelPackage: state.travelPackage };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPassenger, (state, action) => {
        const { ageGroup, passengerId } = action.payload;
        const isFirst = state.passengers.length === 0;
        state.passengers.push({
          ...passengerModel,
          id: passengerId,
          ageGroup: ageGroup,
          main: isFirst,
        });
      })
      .addCase(removePassenger, (state, action) => {
        const { passengerId } = action.payload;
        const pIdx = state.passengers.findIndex((p) => p.id === passengerId);
        if (state.passengers.length > 1 && pIdx !== -1) {
          state.passengers.splice(pIdx, 1);
        }
      })
      .addCase(updatePassenger, (state, action) => {
        const { ageGroup, details, passengerId } = action.payload;
        const pIdx = state.passengers.findIndex((p) => p.id === passengerId);
        if (pIdx === -1) {
          return;
        }
        state.passengers[pIdx].ageGroup = ageGroup;
        state.passengers[pIdx].name = details.name;
        state.passengers[pIdx].surname = details.surname;
        state.passengers[pIdx].nationality = details.nationality;
        state.passengers[pIdx].gender = details.gender;
        state.passengers[pIdx].dob = details.dob;
      })
      .addCase(updateCheckedBaggageInclusion, (state, action) => {
        const { doInclude, passengerId } = action.payload;
        const pIdx = state.passengers.findIndex((p) => p.id === passengerId);
        if (pIdx !== -1 && state.passengers[pIdx].ageGroup !== PASSENGER.infant) {
          state.passengers[pIdx].skipCheckedBaggage = !doInclude;
        }
      })
      .addCase(updateTravelPackage, (state, action) => {
        state.travelPackage = action.payload.travelPackage;
      })
      .addCase(resetAfterBooking, (state, action) => {
        if (action.payload.keepTravelPackage) {
          return { ...bookingModel, travelPackage: state.travelPackage };
        } else {
          return bookingModel;
        }
      });
  },
});

export const bookingActions = bookingSlice.actions;
export default bookingSlice.reducer;

export const selectAllPassengers = (state) => {
  return state.booking.passengers;
};

export const selectisFormIncomplete = (state, step) => {
  return state.booking.step[step].isFormIncomplete;
};

export const selectValidationAttempts = (state, step) => {
  return state.booking.step[step].validationAttempts;
};

export const selectPassenger = (state, passengerId) => {
  return state.booking.passengers.find((p) => p.id === passengerId);
};

export const selectServiceFee = (state) => {
  return getServiceFee(state.booking.travelPackage);
};

export const selectTravelPackage = (state) => {
  return state.booking.travelPackage;
};

export const selectContactData = (state) => {
  return state.booking.contact;
};

export const selectBillingData = (state) => {
  return state.booking.billing;
};

export const selectPaymentData = (state) => {
  return state.booking.payment;
};
