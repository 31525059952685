import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS } from "src/constants/services";
import { selectCatalogueItem } from "src/store/catalogue";
import { BAGGAGE } from "../baggage-utils";
import { customLog } from "../utils";

export function getBaggageInclusionListenerOpts(cb) {
  return {
    type: "passenger/updateCheckedBaggageInclusion",
    effect: (action, listenerApi) => {
      customLog(action);
      const bookingActionsQueue = [];

      const paxData = listenerApi
        .getState()
        .booking.passengers.find((p) => p.id === action.payload.passengerId);
      if (!paxData.passenger_id) return;

      const _oldBag = listenerApi
        .getOriginalState()
        .order.baggage.find((b) => b.passengerId === action.payload.passengerId);

      if (paxData && !action.payload.doInclude) {
        if (_oldBag.checked.serviceId) {
          bookingActionsQueue.push({
            command: BOOKING.REMOVE_SERVICE_ANCILLARY,
            payload: {
              type: "baggage",
              id: BAGGAGE.checked,
              passenger_id: paxData.passenger_id,
              passenger_client_id: paxData.id,
              backend_id: _oldBag.checked._id,
            },
          });
        }
        if (_oldBag.protection.serviceId) {
          const serviceDetails = selectCatalogueItem(
            listenerApi.getState().catalogue.catalogue,
            CATALOGUE_IDS.BAG_PROTECTION
          );
          bookingActionsQueue.push({
            command: BOOKING.REMOVE_SERVICE_ADDITIONAL,
            payload: {
              id: serviceDetails.data.id,
              passenger_id: paxData.passenger_id,
              passenger_client_id: paxData.id,
              backend_id: _oldBag.protection._id,
            },
          });
        }
      }

      bookingActionsQueue.forEach((nextAction) => cb(nextAction));
    },
  };
}
