import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";


export function getPaymentTypeListenerOpts(cb) {
  const types = ["booking/updatePaymentType", "booking/updatePaymentVendor"];
  const paymentListenerOpts = types.map((type) => {
    return {
      type: type,
      effect: (action, listenerApi) => {
        customLog(action);
        const paymentState = listenerApi.getState().booking.payment;
        const bookingAction = {
          command: BOOKING.UPDATE_PAYMENT_TYPE,
          payload: { ...paymentState },
        };
        cb(bookingAction);
      },
    };
  });
  return paymentListenerOpts;
}
