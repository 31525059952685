import { TRIP_TYPE, SORT, STOPS } from "src/constants";
import { BOOKING_STEP } from "src/constants";

export const airportUserInputModel = {
  id: "",
  srcLocation: "",
  srcIata: "",
  destLocation: "",
  destIata: "",
};

export const tripModel = {
  id: "",
  srcLocation: { city: "", iata: "", isCity: false, cityIata: "", names: [] },
  destLocation: { city: "", iata: "", isCity: false, cityIata: "", names: [] },
  startDate: "",
  endDate: "",
};

export const searchDataModel = {
  tripType: TRIP_TYPE.roundtrip,
  trips: [tripModel],
  passengers: { adult: 1, child: 0, infant: 0 },
  cabinClass: "",
};

export const filterSortModel = {
  isSorting: false,
  isReceivingData: false,
  pinned: {
    departure: [],
    arrival: [],
  },
  sort: {
    type: SORT.best,
    best: { price: 0, duration: 0 },
    cheapest: { price: 0, duration: 0 },
    fastest: { price: 0, duration: 0 },
    departure: { price: 0, duration: 0 },
    arrival: { price: 0, duration: 0 },
  },
  filters: {
    cabinBaggage: {
      isOn: false,
      defaultValue: 0,
      value: 0,
      max: 1,
    },
    checkedBaggage: {
      isOn: false,
      defaultValue: 0,
      value: 0,
      max: 2,
    },
    stops: {
      isOn: false,
      defaultValue: STOPS.any,
      value: STOPS.any,
    },
    nightFlight: {
      isOn: false,
      defaultValue: "included",
      value: "included",
    },
    nightStop: {
      isOn: false,
      defaultValue: "included",
      value: "included",
    },
    duration: {
      isOn: false,
      defaultValue: "",
      value: "",
      max: 25,
    },
    stopover: {
      isOn: false,
      defaultValue: "",
      value: "",
      min: 0,
      max: 25,
    },
    departureStartTime: {
      isOn: false,
      defaultValue: "",
      value: "",
    },
    departureEndTime: {
      isOn: false,
      defaultValue: "",
      value: "",
    },
    returnStartTime: {
      isOn: false,
      defaultValue: "",
      value: "",
    },
    returnEndTime: {
      isOn: false,
      defaultValue: "",
      value: "",
    },
    airline: {
      isOn: false,
      defaultValue: "any",
      value: "any",
      options: [],
    },
    airport: {
      isOn: false,
      defaultValue: "any",
      value: "any",
      options: [],
    },
    price: {
      isOn: false,
      defaultValue: "",
      value: "",
      min: 0,
      max: 999,
    },
  },
};

export const passengerModel = {
  id: "",
  main: false,
  ageGroup: "",
  name: "",
  surname: "",
  nationality: "",
  gender: "",
  dob: "",
  skipCheckedBaggage: false,
  committed: false,
};

export const VATDetailsModel = {
  companyName: "",
  companyID: "",
  VATNumber: "",
  registeredAddress: "",
  country: "",
};

export const formState = { isFormIncomplete: false, validationAttempts: 0 };

export const bookingModel = {
  contact: {
    email: "",
    telCode: "",
    tel: "",
    isSubscribedNewsletter: true,
  },
  passengerState: {
    allCommitted: false,
    hasPostCommitChange: false,
  },
  passengers: [],
  billing: {
    isVATRequired: false,
    isCompany: true,
    company: { companyName: "", companyID: "", VATNumber: "", address: "", country: "" },
    person: { name: "", surname: "", address: "", country: "" },
  },
  payment: { type: "", vendor: "" },
  isTermsAgreed: false,
  isOffersOptedOut: false,
  travelPackage: {},
  step: {
    [BOOKING_STEP.fareTypeSelection.id]: { ...formState },
    [BOOKING_STEP.passengerDetailsForm.id]: { ...formState },
    [BOOKING_STEP.guaranteeSelection.id]: { ...formState },
    [BOOKING_STEP.insuranceSelection.id]: { ...formState },
    [BOOKING_STEP.extraServicesSelection.id]: { ...formState },
    [BOOKING_STEP.seatSelection.id]: { ...formState },
    [BOOKING_STEP.checkoutPage.id]: { ...formState },
  },
};
