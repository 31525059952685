import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import objectSupport from "dayjs/plugin/objectSupport";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(objectSupport);
dayjs.extend(utc);

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_DISPLAY = "YYYY.MM.DD";
export const DATE_FORMAT_NO_YEAR = "MM.DD";
export const FLIGHT_DATE_FORMAT = "DD.MM.YYYY";

export const getDisplayDateValue = (date, format = DATE_FORMAT_DISPLAY) => {
  return date ? dayjs(date).format(format) : "";
};

export const getDisplayDateValueNoYear = (date, format = DATE_FORMAT_NO_YEAR) => {
  if (!date) return "";
  return dayjs(date).format(format);
};

export const getFlightDateFormatted = (date, format) => {
  return date ? dayjs(date, FLIGHT_DATE_FORMAT).utc(true).format(format) : "";
};

export const isValidDateString = (dateString) => {
  return dayjs(dateString, DATE_FORMAT, true).isValid();
};

export function isTodayOrLater(dateToCheck) {
  if (!dateToCheck) return;
  return dayjs(dateToCheck).isSameOrAfter(dayjs(), "day");
}

export function secondDateIsLater(startDate, endDate) {
  if (!startDate || !endDate) return;
  return dayjs(endDate).isAfter(dayjs(startDate), "day");
}

export function isDateWithinRange(startDate, dateToCheck, endDate) {
  if (!startDate || !endDate || !dateToCheck) return;
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const middle = dayjs(dateToCheck);

  return start.isBefore(middle, "day") && end.isAfter(middle, "day");
}

export function isDateDisabledInCalendar(minDate, dateToCheck, maxDate) {
  if (!minDate || !maxDate || !dateToCheck) return true;
  const min = dayjs(minDate, DATE_FORMAT);
  const max = dayjs(maxDate, DATE_FORMAT);
  const targetDate = dayjs(dateToCheck, DATE_FORMAT);

  return !(targetDate.isSameOrAfter(min, "day") && targetDate.isSameOrBefore(max, "day"));
}

export function isSameDate(firstDate, secondDate) {
  if (!firstDate || !secondDate) return;
  return dayjs(firstDate).isSame(dayjs(secondDate), "day");
}

export function getSignedDateDifferenceInDays(
  startDateStr,
  endDateStr,
  dateFormat = DATE_FORMAT
) {
  const start = dayjs(startDateStr, dateFormat);
  const end = dayjs(endDateStr, dateFormat);
  if (start.isValid() && end.isValid()) {
    return end.diff(start, "day");
  }

  return undefined;
}

export function addDaysToDate(dateStr, daysToAdd) {
  const inputDate = dayjs(dateStr, DATE_FORMAT);
  const newDate = inputDate.add(daysToAdd, "day");
  return newDate.format(DATE_FORMAT);
}

export function getTimeInMinutes(timeStr) {
  if (!timeStr) return;
  const time = dayjs(timeStr, "HH:mm");
  return time.hour() * 60 + time.minute();
}

export function getTimeFromMinutes(minutes) {
  if (!Number.isInteger(minutes) && !minutes) return;
  return dayjs().startOf("day").add(minutes, "minute").format("HH:mm");
}

export function getTimeDifferenceInMinutes(timestamp1, timestamp2) {
  const millisDiff = Math.abs(timestamp1 * 1000 - timestamp2 * 1000);
  return Math.floor(millisDiff / (60 * 1000));
}

export function getTimeDifferenceInDays(timestamp1, timestamp2) {
  const date1 = dayjs.unix(timestamp1);
  const date2 = dayjs.unix(timestamp2);
  return Math.abs(date2.diff(date1, "day"));
}

export function getFormattedDateFromParams(day, month, year) {
  const date = dayjs({ year, month: month - 1, day });
  if (date.isValid()) return date.format(DATE_FORMAT);

  return "";
}

// Function to check if a date of birth falls within a specified age range
export function isValidAgeOnFlightDate(dobDateStr, maxAge, minAge, flightDateStr) {
  const flightDate = dayjs(flightDateStr, FLIGHT_DATE_FORMAT).startOf("day");
  const dob = dayjs(dobDateStr, DATE_FORMAT).startOf("day");
  const isDOBInThePast = dob.isSameOrBefore(dayjs(), "day");
  const ageOnFlightDate = flightDate.diff(dob, "year");
  return ageOnFlightDate >= minAge && ageOnFlightDate < maxAge && isDOBInThePast;
}

// dateStr is a string in the format "YYYY-MM-DD"
export function getDateObjectFromDateString(dateStr) {
  const date = dayjs(dateStr, DATE_FORMAT);
  const dobObj = { day: date.date(), month: date.month() + 1, year: date.year() };
  return dobObj;
}

export function getMonthFromDate(date) {
  return dayjs(date, FLIGHT_DATE_FORMAT).month(); // 0-indexed (0 = January)
}

export function getDayFromDate(date) {
  return dayjs(date, FLIGHT_DATE_FORMAT).date();
}

export function getWeekdayFromDate(date, format = FLIGHT_DATE_FORMAT) {
  // Returns 0 for Sunday, 1 for Monday, etc.
  const weekdayNum = dayjs(date, format).day();
  return weekdayNum > 0 ? weekdayNum - 1 : 6;
}

export function reformatDate(date, originalFormat, newFormat) {
  return dayjs(date, originalFormat).format(newFormat);
}

const abbreviations = {
  en_GB: { hour: "h", minute: "min" },
  ru_RU: { hour: "ч", minute: "мин" },
  lv_LV: { hour: "st", minute: "min" },
  lt_LT: { hour: "val", minute: "min" },
  et_EE: { hour: "t", minute: "min" },
};
export function getLocalizedFlightDuration(totalMinutes, locale = "en_GB") {
  if (totalMinutes === undefined) {
    return undefined;
  }
  const hours = Math.trunc(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let str = hours > 0 ? `${hours}${abbreviations[locale].hour} ` : "";
  str += minutes > 0 ? `${minutes}${abbreviations[locale].minute} ` : "";
  return str.trim();
}
