import { defer, json } from "react-router-dom";
import { bookingOrderApi } from "src/services/api";
import { isRoundTrip } from "src/utils/results-utils";
import { getSelectedLocale } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

function formatFlightDate(timestampSeconds, lang) {
  const date = new Date(timestampSeconds * 1000);
  const options = {
    day: "2-digit",
    month: "short",
  };
  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);
  return formattedDate;
}

function formatDataItem(trip, lang, i) {
  trip.tripType = isRoundTrip(trip) ? "roundtrip" : "oneway";
  trip.from = trip.segments[0].dep.city.title?.split(",")[0];
  const desination = trip.segments_direction[0].at(-1);
  trip.to = trip.segments[desination].arr.city.title?.split(",")[0];
  const startDateStr = formatFlightDate(trip.segments[0].dep.timestamp, lang);
  const endDateStr = formatFlightDate(trip.segments.at(-1).arr.timestamp, lang);
  trip.dates =
    trip.tripType === "roundtrip" ? `${startDateStr} - ${endDateStr}` : startDateStr;
  trip.imageUrl =
    `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
    `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cities/` +
    `${trip.segments[desination].arr.city.code}.jpg`;
  trip.paid = trip.amountPaid >= trip.totalBookingPrice;
  return trip;
}

export async function loader({ request, user }) {
  console.log("userTripsLoader");
  if (isLangAbsent(request)) return redirectWithLang(request);
  const url = new URL(request.url);
  const searchParams = url.searchParams;

  const lang = searchParams.get("lang") || getSelectedLocale().split("_")[0];
  if (!user) return null;

  const allTrips = bookingOrderApi
    .getUserTrips(user.uid)
    .then((allTripsRes) => {
      const allTrips = allTripsRes.data;
      allTrips.forEach((trip, i) => formatDataItem(trip, lang, i));
      const [trips, pastTrips] = allTrips.reduce(
        (acc, trip) => {
          const targetArr = trip.segments[0].dep.timestamp * 1000 < Date.now() ? 1 : 0;
          if (targetArr === 1) trip.past = true;
          acc[targetArr].push(trip);
          return acc;
        },
        [[], []]
      );

      trips.sort((a, b) => a.segments[0].dep.timestamp - b.segments[0].dep.timestamp);
      pastTrips.sort((a, b) => b.segments[0].dep.timestamp - a.segments[0].dep.timestamp);
      console.log(trips);
      console.log(pastTrips);
      return [trips, pastTrips];
    })
    .catch((err) => {
      customLog(err);
      if (err.response?.status === 404) {
        return [[], []];
      }
      throw json({ data: err });
    });

  return defer({ allTrips });
}
