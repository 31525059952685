import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";


export function getNewsletterSubListenerOpts(cb) {
  return {
    type: "booking/changeNewsletterSubscription",
    effect: (action, listenerApi) => {
      customLog(action);
      const isSubscription = listenerApi.getState().booking.contact.isSubscribedNewsletter;
      const bookingAction = {
        command: BOOKING.UPDATE_NEWSLETTER_SUBSCRIPTION,
        payload: { isSubscription },
      };
      cb(bookingAction);
    },
  };
}
