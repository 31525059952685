import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS } from "src/constants/services";
import { selectCatalogueItem } from "src/store/catalogue";
import { customLog } from "../utils";

export function getSeatingListenerOpts(cb) {
  return {
    type: "order/changeSeatType",
    effect: (action, listenerApi) => {
      customLog(action);

      const segment = action.payload.segment;
      const _new = listenerApi.getState().order.seatType[segment];
      const _old = listenerApi.getOriginalState().order.seatType[segment];

      if (_new.serviceId !== _old.serviceId) {
        const bookingActionsQueue = [];

        const catalogue = listenerApi.getState().catalogue.catalogue;
        const serviceDetails = selectCatalogueItem(catalogue, _new.serviceId);

        if (_old.serviceId !== CATALOGUE_IDS.seats.random) {
          bookingActionsQueue.push({
            command: BOOKING.REMOVE_SERVICE_ANCILLARY,
            payload: { type: "seats", segment, backend_id: _old._id },
          });
        }

        if (_new.serviceId !== CATALOGUE_IDS.seats.random) {
          const seatServiceSubtype = Object.entries(CATALOGUE_IDS.seats).find(
            (e) => e[1] === _new.serviceId
          )[0];
          const bookingAction = {
            command: BOOKING.ADD_SERVICE_ANCILLARY,
            payload: {
              type: "seats",
              id: seatServiceSubtype,
              segment,
              airline: _new.airline,
              data: { ...serviceDetails.data[_new.airline] },
              backend_id: _new._id,
            },
          };
          bookingActionsQueue.push(bookingAction);
        }

        bookingActionsQueue.forEach((nextAction) => cb(nextAction));
      }
    },
  };
}
