import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import searchDataSlice from "./search";
import filterSortSlice from "./filter-sort";
import orderSlice from "./order";
import bookingSlice from "./booking";
import catalogueSlice, { catalogueMiddleware } from "./catalogue";
import cartOverviewSlice, { cartMiddleware } from "./cartOverview";
import bookingUpdatesSlice from "./bookingUpdates";

const listenerMiddleware = createListenerMiddleware();

const store = configureStore({
  reducer: {
    search: searchDataSlice,
    filterSort: filterSortSlice,
    order: orderSlice,
    booking: bookingSlice,
    catalogue: catalogueSlice,
    cartOverview: cartOverviewSlice,
    bookingUpdates: bookingUpdatesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat([cartMiddleware, catalogueMiddleware]),
});

export default store;
