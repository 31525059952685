import SaleBanners from "src/components/features/home/SaleBanners/SaleBanners";
import TopDestinations from "src/components/features/home/TopDestinations/TopDestinations";
import SearchForm from "src/components/features/search/SearchForm/SearchForm";
import Footer from "src/components/shared/footer/Footer/Footer";
import { useDeviceContext } from "src/context/device-context";
import useLoginPopup from "src/hooks/useLoginPopup";

import useSyncChildren from "src/hooks/useSyncChildren";

function HomePage() {
  const { isMobile } = useDeviceContext();
  const { onLoad, isSynced } = useSyncChildren(["footer"]);
  useLoginPopup();

  return (
    <div style={{ display: isSynced ? "contents" : "none" }}>
      <main id="tickets-home-page" className={isMobile ? "mobile" : "desktop"}>
        <SearchForm />
        <div style={{ backgroundColor: "#fff" }}>
          <SaleBanners />
          <TopDestinations />
        </div>
      </main>
      <Footer onLoad={onLoad} />
    </div>
  );
}

export default HomePage;
