import { SORT } from ".";

export const SORT_TYPE_MAP = {
  [SORT.best]: "res.sort.best",
  [SORT.cheapest]: "res.sort.cheapest",
  [SORT.fastest]: "res.sort.fastest",
  [SORT.earlyDeparture]: "res.sort.departure",
  [SORT.earlyArrival]: "res.sort.arrival",
};

export const AIRLINE_CABIN_TYPE = {
  E: "sf.cabin.type.economy",
  B: "sf.cabin.type.business",
  F: "sf.cabin.type.first",
  W: "sf.cabin.type.comfort",
  A: "sf.cabin.type.all",
};

export const WEEKDAY_SHORT_STR_MAP = [
  "calendar.weekday.mon",
  "calendar.weekday.tue",
  "calendar.weekday.wed",
  "calendar.weekday.thu",
  "calendar.weekday.fri",
  "calendar.weekday.sat",
  "calendar.weekday.sun",
];

export const ALL_MONTHS_MAP = [
  "calendar.month.jan",
  "calendar.month.feb",
  "calendar.month.mar",
  "calendar.month.apr",
  "calendar.month.may",
  "calendar.month.jun",
  "calendar.month.jul",
  "calendar.month.aug",
  "calendar.month.sep",
  "calendar.month.oct",
  "calendar.month.nov",
  "calendar.month.dec",
];

export const GENDER = {
  female: "booking.form.passenger.gender.female",
  male: "booking.form.passenger.gender.male",
};
