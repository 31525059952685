import { BOOKING } from "src/constants/booking";
import { customLog } from "../utils";
import { DIGIT_PATTERN, EMAIL_PATTERN } from "src/constants/validation";

export function getContactDataListenerOpts(cb) {
  return {
    type: "booking/updateContactData",
    effect: (action, listenerApi) => {
      customLog(action);
      const _oldContact = listenerApi.getOriginalState().booking.contact;
      const _contact = listenerApi.getState().booking.contact;
      const bookingCommands = [];

      bookingCommands.push({
        command: BOOKING.UPDATE_CONTACT_INFO,
        payload: { ..._contact },
      });

      const wasIncomplete =
        !_oldContact.email ||
        !_oldContact.tel ||
        !_oldContact.telCode ||
        !EMAIL_PATTERN.test(_oldContact.email) ||
        !DIGIT_PATTERN.test(_oldContact.tel) ||
        _oldContact.tel.length < 8;

      const isComplete =
        _contact.email &&
        _contact.tel &&
        _contact.telCode &&
        EMAIL_PATTERN.test(_contact.email) &&
        DIGIT_PATTERN.test(_contact.tel) &&
        _contact.tel.length >= 8;

      if (wasIncomplete && isComplete) {
        bookingCommands.push({ command: BOOKING.CONTACT_DATA_COMPLETE });
      }

      bookingCommands.forEach((nextAction) => cb(nextAction));
    },
  };
}
